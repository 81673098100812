<template>
  <q-layout>
    <q-page-container>
      <q-page class="flex bg-image flex-center">
        <q-card v-bind:style="$q.screen.lt.sm?{'width': '80%'}:{'width':'30%'}" v-bind:class="$q.screen.lt.sm?'q-pa-sm':'q-pa-lg'">

          <q-card-section>
            <div class="text-center ">
                 <q-avatar size="70px" class=" shadow-10">
              <img src="@/assets/profile.svg">
            </q-avatar>
              <div v-if="!$q.screen.lt.sm" class="col text-h5 ellipsis q-mt-lg">Войдите, чтобы продолжить</div>
              <div v-if="$q.screen.lt.sm" class="col text-h5 ellipsis q-mt-lg">Войдите</div>
            </div>
          </q-card-section>
          <q-card-section>
            <q-form  class="q-gutter-md" @submit.prevent="loginSubmit" @reset="formReset" ref="loginForm">
            
              <q-input
                filled
                v-model="form.username"
                label="Введите логин"
                lazy-rules
                :rules="[val => !!val || '']"
              />

              <q-input 
                v-model="form.password" 
                filled 
                :type="isPwd ? 'password' : 'text'" 
                label="Введите пароль" 
                lazy-rules
                :rules="[val => !!val || '']">
                    <template v-slot:append>
                    <q-icon
                        :name="isPwd ? 'visibility_off' : 'visibility'"
                        class="cursor-pointer"
                        @click="isPwd = !isPwd"
                    />
                    </template>
                </q-input>

              <div class="col-6">
                  <q-btn 
                   
                    type="submit"
                    label="Войти" 
                    :loading="loader" 
                    color="primary"
                    size="lg"
                    class="full-width"
                >
                    <template v-slot:loading>
                        <q-spinner-facebook />
                    </template>
                </q-btn>
              
              </div>
             <div class="col-6"><a style="text-decoration:none;color: var(--q-color-primary) !important;" href="/restore">Забыли пароль?</a></div>
                
                <q-banner v-if="formError" rounded inline-actions class="text-white text-center bg-red">
                    Неправильный логин или пароль.
                </q-banner>
            </q-form>
          </q-card-section>
        </q-card>
      </q-page>
    </q-page-container>
  </q-layout>
</template>

<script>
    export default {
        data() {
            return {
              form:{
                username: null,
                password: null,
              },
              isPwd: false,
              loader: false,
              formError:false
            }
        },

        methods:{
            formReset(){
                this.$refs.loginForm.resetValidation()
                this.formError = true
                this.form.password = null
                this.form.username = null
                this.loader = false
            },
            loginSubmit(){
                this.loader = true
                this.formError = false
                this.$store.dispatch('login', this.form)
                .then((res)=>{
                  if(res){
                    document.location.href = "/"
                  }else{
                    this.formReset()
                  }
                })
                .catch(err => {
                  this.formReset()
                  console.log(err)
                }) 
            }
        }
    }
</script>

<style>
  .bg-image {
   background-color: #ebedef;;
  }
</style>