import { render, staticRenderFns } from "./login.vue?vue&type=template&id=b5403db6&"
import script from "./login.vue?vue&type=script&lang=js&"
export * from "./login.vue?vue&type=script&lang=js&"
import style0 from "./login.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QLayout from 'quasar/src/components/layout/QLayout.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpinnerFacebook from 'quasar/src/components/spinner/QSpinnerFacebook.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QBanner from 'quasar/src/components/banner/QBanner.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QLayout,QPageContainer,QPage,QCard,QCardSection,QAvatar,QForm,QInput,QIcon,QBtn,QSpinnerFacebook,QColor,QBanner});
